import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Layout from "../components/layout";
import SEO from "../components/seo"
import Articles from '../components/articles';

// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/contact.module.scss"

import how from "../assets/img/jobs-icons/how-we-work.svg"
import team from "../assets/img/jobs-icons/team.svg"
import benefits from "../assets/img/jobs-icons/benefits.svg"

let lang = ""

const goToURL = (e: any, url: string) => {
  window.location.assign(url)
  e.preventDefault()
}

const position = () => {

  lang = useIntl().locale;

  const data = useStaticQuery(graphql`
    query ImagesPosition {
      office: file( relativePath: { eq: "joinUsOffice.jpg"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }


    }
  `);

  const title = useIntl().formatMessage({id: 'position'});  

  const [load] = useState(0);

  return (
    <Layout color="">
      <SEO 
      lang={lang}
      title={title}
      /> 
      <article className={ 'container-small ' + styles.article + " " + "space"}>
        <div className="container-large">
          <h1>Diseñador UX / UI</h1>
          <p>Descripción del puesto</p>
          <div className="space"></div>    
            <div className="grid-cards-2">
              <div>  
                <h4>Formación:</h4>
                  <p>Diseño gráfico o diseño industrial. <br/>
                  Aplican personas autodidactas.
                  </p>
                  </div>
                  <div>
                <h4>Experiencia: </h4>
                <p>Más de 2 años de experiencia en diseño de interfaces y tecnología. <br/>
                   Se valora experiencia o conocimientos de maquetación. <br/>
                   Nivel intermedio de inglés.
                </p>
              </div>
          </div>              
        </div>

        <div className="blueContainer">
        <div className={styles.topLineWhite}></div>
        <div className={"container-large " + styles.whiteBackground}>
          <div className={"grid-cards-2-1 " + styles.container}>
            <div>
              <h2>
              <FormattedMessage id="position_title_1" values={{breakLine:<br/>}}/>
              </h2>
            </div>
          </div>
          <div className="space"></div>
          <div>
              <img src={how} alt=""/>
                <h3 className={styles.joinUsTitle}>
                  <FormattedMessage id="position_title_2" values={{breakLine:<br/>}}/>
                </h3>
            </div>
            <div className="space-small"></div>
          <div className={"grid-cards-2-1 "}>
            <div>
              <h4><FormattedMessage id="position_subtitle_1" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_1" values={{breakLine:<br/>}}/>
                </p>
            </div>
            <div>
              <h4><FormattedMessage id="position_subtitle_2" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_2" values={{breakLine:<br/>}}/>
                </p>
            </div>
            <div>
              <h4><FormattedMessage id="position_subtitle_3" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_3" values={{breakLine:<br/>}}/> 
                </p>
            </div>
          </div>

          <div className="space"></div>
          <div>
              <img src={team} alt=""/>
                <h3 className={styles.joinUsTitle}>
                <FormattedMessage id="position_title_4" values={{breakLine:<br/>}}/>
                </h3>
            </div>
            <div className="space-small"></div>
          <div className={"grid-cards-2-1 "}>
            <div>
              <h4><FormattedMessage id="position_subtitle_4" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_4" values={{breakLine:<br/>}}/> 
                </p>
            </div>
            <div>
            <h4><FormattedMessage id="position_subtitle_5" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_5" values={{breakLine:<br/>}}/> 
                </p>
            </div>
          </div>

          <div className="space"></div>
          <div>
              <img src={benefits} alt=""/>
                <h3 className={styles.joinUsTitle}>
                  <FormattedMessage id="position_title_4" values={{breakLine:<br/>}}/>
                </h3>
            </div>
            <div className="space-small"></div>
          <div className={"grid-cards-2-1 "}>
            <div>
            <div className={ styles.picture}>
                <figure className={ styles.picture}>
                  <Img fluid={ data.office.childImageSharp.fluid } alt="Office Picture" />
                </figure>
              </div>
            </div>
            <div>
              <h4><FormattedMessage id="position_subtitle_6" values={{breakLine:<br/>}}/></h4>
                <p>
                  <FormattedMessage id="position_paragraph_6" values={{breakLine:<br/>}}/> 
                </p>
            </div>
          </div>
          <div className="space"></div>
        </div>
        <div className={styles.bottomLineWhite}></div>
        </div>
        
        <div className="space"></div>
        <div className="container-large">
        <h2><FormattedMessage id="apply" values={{breakLine:<br/>}}/> </h2>
        <div className="space"></div>
        <form action="">
        <div className={"grid-cards-2-1 "}>
        <div>
          <label className={"p-small"+" "+styles.label} htmlFor="name"><FormattedMessage id="contact_name"/></label>
          <input type="text" id="name" className={styles.inputContact} />
        </div>

        <div>
        <label className={"p-small"+" "+styles.label} htmlFor="email"><FormattedMessage id="contact_email"/></label>
          <input type="text" id="email" className={styles.inputContact} />
        </div>

        <div>
          <label className={"p-small"+" "+styles.label} htmlFor="phone"><FormattedMessage id="contact_phone"/></label>
          <input type="phone" id="phone" className={styles.inputContact} />
        </div>

        <div>
          <label className={"p-small"+" "+styles.label} htmlFor="phone"><FormattedMessage id="attach_files_title"/></label>
          <div className={styles.dragAndDrop}>
          <p className={"p-small"+" "+styles.label}><FormattedMessage id="attach_files"/></p>
          </div>
        </div>

        </div>
        <label className={"p-small"+" "+styles.label} htmlFor="text"><FormattedMessage id="text"/></label>
        <textarea name="text" id="text" ></textarea>

        <label className={"p-small"+" "+styles.label} htmlFor="text"><FormattedMessage id="how"/></label>
        <textarea name="text" id="text" ></textarea>

        <div className={"grid-cards-2-1 " + styles.keepInfo}>
          <div>
          <input type="checkbox" id="info" name="info"/>
          <label htmlFor="info" className={styles.info}><FormattedMessage id="keep_info" values={{breakLine:<br/>}}/></label>
          </div>
            <button className={"btn btn-main " + styles.contactButton}><FormattedMessage id="contact_send_button"/></button>
          </div>
        </form> 
        </div>
        <div className="space"></div>
      </article>
    </Layout>
  )
}

export default position;